<template lang="html">

  <section class="privacy-policy">
    <img src="/img/icons/detail-line-black1.svg" alt="linea" class="detail2">
    <img src="/img/icons/detail-line-black2.svg" alt="linea" class="detail1">
    <img src="/img/icons/detail-line-black1.svg" alt="linea" class="detail3">
    <img src="/img/icons/detail-line-black2.svg" alt="linea" class="detail4">
    <section class="banner">
      <div class="container  d-flex jcsb aife">
        <div class="content">
          <p class="title-big -whiteC">{{ $t("privacy_policy.privacy_policy") }}</p>
          <p>{{ $t("privacy_policy.subtitle") }}
          </p>

        </div>
        <div class="div-img">
          <img src="/img/illustrations/head-privacy.svg" alt="icono head privacy">
        </div>
      </div>
    </section>
    <section class="content container">
      <p class="title-medium">
        {{ $t("privacy_policy.basic_information") }}
      </p>
      <p><strong>{{ $t("privacy_policy.data_controller") }}</strong></p>
      <ul>
        <li>{{ $t("privacy_policy.data_controller_li") }}</li>
      </ul>

      <p><strong>{{ $t("privacy_policy.purposes") }}</strong></p>
      <ul>
        <li>{{ $t("privacy_policy.purpose_li_1") }}</li>
        <li>{{ $t("privacy_policy.purpose_li_2") }}</li>
        <li>{{ $t("privacy_policy.purpose_li_3") }}</li>
        <li>{{ $t("privacy_policy.purpose_li_4") }}</li>
        <li>{{ $t("privacy_policy.purpose_li_5") }}</li>
        <li>{{ $t("privacy_policy.purpose_li_6") }}</li>
      </ul>

      <p><strong>{{ $t("privacy_policy.legitimation") }}</strong></p>
      <ul>
        <li>{{ $t("privacy_policy.legitimation_li_1") }}</li>
        <li>{{ $t("privacy_policy.legitimation_li_2") }}</li>
        <li>{{ $t("privacy_policy.legitimation_li_3") }}</li>
        <li>{{ $t("privacy_policy.legitimation_li_4") }}</li>
      </ul>

      <p><strong>
          {{ $t("privacy_policy.retention") }}
        </strong></p>
      <ul>
        <li>{{ $t("privacy_policy.retention_li") }}</li>
      </ul>

      <p><strong>{{ $t("privacy_policy.recipients") }}</strong></p>
      <ul>
        <li>{{ $t("privacy_policy.recipients_li") }}</li>
      </ul>

      <p><strong>{{ $t("privacy_policy.rights") }}</strong></p>
      <ul>
        <li>{{ $t("privacy_policy.rights_li") }}</li>
      </ul>

      <p><strong>{{ $t("privacy_policy.additional_information") }}</strong></p>
      <ul>
        <li>{{ $t("privacy_policy.additional_information_li") }}</li>
      </ul>
      <p class="title-medium">
        {{ $t("privacy_policy.additional_information") }}
      </p>
      <p> {{ $t("privacy_policy.additional_information_text") }}</p>
      <p><strong> {{ $t("privacy_policy.who_is") }}</strong></p>
      <p> {{ $t("privacy_policy.who_is_text") }}</p>

      <p> {{ $t("privacy_policy.subdomains") }}</p>
      <p><a href="https://r-sami.com" target="_blank" rel="noopener noreferrer">https://r-sami.com</a></p>
      <p><a href=" https://app.r-sami.com" target="_blank" rel="noopener noreferrer"> https://app.r-sami.com</a></p>


      <p> {{ $t("privacy_policy.other_domains") }}</p>
      <p><a href="https://www.linkedin.com/company/r-sami/about/" target="_blank" rel="noopener noreferrer">LinkedIn</a></p>
      <p><strong> {{ $t("privacy_policy.social_networks") }}</strong></p>

      <p>{{ $t("privacy_policy.social_networks_1") }}</p>
      <p>{{ $t("privacy_policy.social_networks_2") }}</p>
      <p>{{ $t("privacy_policy.social_networks_3") }}</p>

      <p><strong>{{ $t("privacy_policy.personal_data") }}</strong></p>

      <p>{{ $t("privacy_policy.personal_data_text") }}</p>
      <ul>
        <li>{{ $t("privacy_policy.personal_data_li_1") }}</li>
        <li>{{ $t("privacy_policy.personal_data_li_2") }}</li>
        <li>{{ $t("privacy_policy.personal_data_li_3") }}</li>
        <li> {{ $t("privacy_policy.personal_data_li_4") }}</li>
        <li>{{ $t("privacy_policy.personal_data_li_5") }}</li>
      </ul>
      <p>{{ $t("privacy_policy.personal_data_final_text") }}</p>

      <p><strong>{{ $t("privacy_policy.process") }}</strong></p>

      <p>{{ $t("privacy_policy.process_text") }}</p>
      <ul>
        <li>{{ $t("privacy_policy.process_li_1") }}</li>
        <li>{{ $t("privacy_policy.process_li_2") }}</li>
        <li>{{ $t("privacy_policy.process_li_3") }}</li>
        <li>{{ $t("privacy_policy.process_li_4") }}</li>
      </ul>
      <p>{{ $t("privacy_policy.process_text2") }}</p>
      <p><strong>
          {{ $t("privacy_policy.legitimacy") }}
        </strong></p>
      <p>{{ $t("privacy_policy.legitimacy_text") }}</p>
      <ul>
        <li>{{ $t("privacy_policy.legitimacy_li_1") }}</li>
        <li>{{ $t("privacy_policy.legitimacy_li_2") }}</li>
        <li>{{ $t("privacy_policy.legitimacy_li_3") }}</li>
      </ul>

      <p>{{ $t("privacy_policy.legitimacy_text_2") }}</p>
      <ul>
        <li>{{ $t("privacy_policy.legitimacy_li_4") }}</li>
        <li>{{ $t("privacy_policy.legitimacy_li_5") }}</li>

      </ul>
      <p><strong>
          <li>{{ $t("privacy_policy.keep_data") }}</li>
        </strong></p>
      <p>{{ $t("privacy_policy.keep_data_text1") }}</p>
      <p>{{ $t("privacy_policy.keep_data_text2") }}</p>
      <p>{{ $t("privacy_policy.keep_data_text3") }}
        <a href="mailto:info@r-sami.com">info@r-sami.com</a>{{ $t("privacy_policy.keep_data_text3_1") }}
      </p>

      <p><strong> {{ $t("privacy_policy.recipients_data") }}</strong></p>

      <p>{{ $t("privacy_policy.recipients_data_text1") }}</p>
      <p>{{ $t("privacy_policy.recipients_data_text2") }}</p>

      <p><strong> {{ $t("privacy_policy.data_stored") }}</strong></p>
      <p>{{ $t("privacy_policy.data_stored_text") }}</p>

      <p><strong>{{ $t("privacy_policy.rights_have") }}</strong></p>

      <p>{{ $t("privacy_policy.rights_have_text1") }} <a href="mailto:info@r-sami.com">info@r-sami.com</a>.</p>
      <p>{{ $t("privacy_policy.rights_have_text2") }}</p>
      <ul>
        <li>{{ $t("privacy_policy.rights_have_li_1") }}</li>
        <li>{{ $t("privacy_policy.rights_have_li_2") }}</li>
        <li>{{ $t("privacy_policy.rights_have_li_3") }}</li>
        <li>{{ $t("privacy_policy.rights_have_li_4") }}</li>
        <li>{{ $t("privacy_policy.rights_have_li_5") }}</li>
        <li>{{ $t("privacy_policy.rights_have_li_6") }}</li>
        <li>{{ $t("privacy_policy.rights_have_li_7") }}</li>

      </ul>

      <p>{{ $t("privacy_policy.rights_have_text3") }}</p>
      <p>{{ $t("privacy_policy.rights_have_text4") }}</p>
      <p><strong>{{ $t("privacy_policy.responsible") }}</strong></p>

      <p>{{ $t("privacy_policy.responsible_text1") }}</p>
      <p>{{ $t("privacy_policy.responsible_text2") }}
      </p>
      <p><strong>
          {{ $t("privacy_policy.security") }}
        </strong></p>

      <p>{{ $t("privacy_policy.security_text") }}</p>

      <p><strong>{{ $t("privacy_policy.cookies") }}</strong></p>

      <p>{{ $t("privacy_policy.cookies_text1") }}
      </p>
      <p>{{ $t("privacy_policy.cookies_text2") }}</p>

      <p>{{ $t("privacy_policy.cookies_text3") }}</p>
      <p>{{ $t("privacy_policy.cookies_text4") }}
      </p>
    </section>
  </section>

</template>

<script lang="js">

export default {
  name: 'privacy-policy',
  props: [],
  mounted() {

  },
  data() {
    return {

    }
  },
  methods: {

  },
  computed: {

  }
}


</script>

<style scoped lang="scss">
.privacy-policy {}
</style>